<template>
  <div class="ContactAgreement">
    <div class="content">
      <div style="padding: 20px;">
        <a-form :form="form" :layout="formLayout">
          <a-form-item label="适用中心" wrapper-col="10">
            <a-select show-search :filter-option="filterOption"
                    style="width: 500px; margin-right: 15px"
                    v-model="ManageAreaId"
            >
              <a-select-option value="">请选择
              </a-select-option>
              <a-select-option
                      :value="item.Id"
                      v-for="(item, index) in orgList"
              >{{ item.OrgPath }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="优先级" wrapper-col="10">
            <a-input-number id="inputNumber" v-model="Priority" :min="1" :max="10" @change="onChange" />
          </a-form-item>
          <a-form-item label="">
            <a-button type="primary" @click="save">保存</a-button>
          </a-form-item>
          <a-form-item label="">
            <a-button type="danger" style="margin-left: 20px;">取消</a-button>
          </a-form-item>
        </a-form>
      </div>
      <TinyEditor v-model="html" id="tinymce"></TinyEditor>
    </div>
  </div>
</template>
<script>
  import TinyEditor from "../../components/TinyEditor/TinyEditor";
  export default {
    components:{TinyEditor},
    name: 'add',
    data () {
      return {
        value: 3,
        formLayout: 'inline',
        form: this.$form.createForm(this, { name: 'coordinated' }),
        orgList:[
        ],
        ManageAreaId:'',
        Priority:'',
        html:'',
      }
    },

    mounted () {
      this.getOrg();
    },
    methods:{
      filterOption(input, option) {
        return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      getOrg(){
        this.$axios.get(
                20015,
                { Id: this.$route.query.Id},
                res => {
                  if (res.data.code == 1) {
                    this.orgList = res.data.data
                  } else {
                    this.$message.error(res.data.msg)
                  }
                }
        )
      },
      onChange(value) {
        this.Priority = value;
      },
      save(){
        this.$axios.post(
                20014,
                { ManageAreaId: this.ManageAreaId,Priority:this.Priority,Items:this.html},
                res => {
                  if (res.data.code == 1) {
                    this.$message.success(res.data.msg)
                    this.$router.push({ path: '/ContactAgreement'})
                  } else {
                    this.$message.error(res.data.msg)
                  }

                }
        )
      }
    }
  }
</script>
<style lang="less" scoped>
  .ContactAgreement {
  }
  .content{
    background-color: white;
    height: 100%;
  }
</style>
